$primary-blue-color: #007dd2;
$secondary-blue-color: #005a96;

$menu-primary-color: #e5f2fa;
$menu-hover-color: #cdecf8;

$selector-border-color: #f8f8f8;
$selector-font-color: #d9d9d9;

$menu-border-color: #e6e6e6;

$incorrect-item-hover-color: #fdd4d0;
$correct-item-hover-color: #d7f0db;

$color-white: #fff;
$color-black: #000;
