@import '../../../../assets/utilities/variables';

.question-info-wrapper {
  box-sizing: border-box;
  padding: 0 20px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $container-shadow-color;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 20px;
  }

  &.question-info-wrapper-sticky {
    .question-info-wrapper__open {
      position: sticky;
      top: 0;
    }
  }

  .question-info-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;

    h2 {
      color: $font-color-alternative;
      font-family: $font-family-default;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
    }

    .toggle-button {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .question-info-content {
    margin-bottom: 20px;

    .question-wrapper .qti-candidateResponse {
      display: none;
    }

    .question-wrapper .qti-correctResponse {
      display: block;
      margin-top: 10px;
    }

    .correct-answer {
      padding: 16px 16px 25px;
      border: 1px solid $color-gray-9;
      border-radius: 4px;

      h4 {
        text-transform: uppercase;
        margin: 0 0 10px 0;
        color: $font-color-faded;
        font-family: $font-family-default;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }

      .qti-answer-model {
        font-family: $font-family-default;
        color: $font-color-alternative;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }
}

.question-info-wrapper-sticky {
  &.question-info-wrapper__open {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
