@import '../../../assets/utilities/variables.scss';
@import '../../../assets/utilities/containerVariables';
@import '../../../assets/utilities/toetsplatformTheme';

.navigation {
  padding: 20px 0;
  display: flex;
  align-items: center;

  &__section {
    display: flex;
  }

  &__button-description {
    max-width: 320px;
    font-size: 14px;
    font-family: $font-family-default;
    text-align: center;
    margin-top: 12px;
    padding: 0 12px;
  }

  &__button {
    &--next {
      justify-content: space-between;
      width: 132px;
      padding: 0 20px;
      @include icon($color-dark-gray-1) {
        margin-left: 6px;
        position: relative;
        top: 2px;
      }

      &:disabled {
        @include icon($font-color-disabled);
      }
    }

    &--back {
      margin-right: 30px;
    }

    &--finish {
      width: 160px;
    }

    &--custom {
      width: 132px;
      background-color: $primary-blue-color;
      color: $color-white;
      margin-right: 30px;
    }
  }
}
