@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

$day-default-hover: $color-gray-7;
$day-selected: $color-light-blue-2;
$day-selected-hover: $color-light-blue-1;
$day-range: $primary-light-blue-color;
$day-range-hover: $color-light-blue-2;
$day-font-color: $font-color-default;

.picker-container {
  margin-left: 26px;
  box-sizing: border-box;
}

.DateInput {
  width: 200px;
  height: 40px;
  margin-right: 20px;
  border-radius: 4px;
  color: $font-color-default;
  background-color: transparent;

  &_input {
    text-align: center;
    color: $font-color-default;
    font-size: 14px;
    padding: 10px 0;
    font-family: $font-family-default;
    background-color: transparent;
    border-bottom: none;
    height: 40px;
    box-sizing: border-box;

    &__focused {
      border-bottom: none;
    }

    &__disabled {
      background-color: $input-disabled-background;
      color: $font-color-secondary;
      font-style: normal;
      pointer-events: none;
    }
  }
}

.CalendarDay {
  &__default {
    &:hover {
      background-color: $day-default-hover;
      color: $day-font-color;
    }
  }

  &__selected {
    background-color: $day-selected;
    border: none;
    color: $day-font-color;

    &:hover {
      background-color: $day-selected-hover;
      border: none;
      color: $day-font-color;
    }

    &_span {
      background-color: $day-range;
      border: none;
      color: $day-font-color;

      &:hover {
        background: $day-selected-hover;
        border: none;
        color: $day-font-color;
      }
    }
  }

  &__hovered_span {
    background: $day-range;
    border: none;
    color: $day-font-color;

    &:hover {
      background: $day-range-hover;
      border: none;
      color: $day-font-color;
    }
  }
}

.DateRangePickerInput,
.SingleDatePickerInput {
  background-color: transparent;

  &__withBorder {
    border: 1px solid $input-border;
    border-radius: 4px;
  }
  &__disabled {
    background-color: $input-disabled-background;
    color: $font-color-secondary;
    font-style: normal;
    pointer-events: none;
  }
}

.SingleDatePickerInput {
  &__withBorder {
    border: 1px solid $input-border;
    border-radius: 4px;
    width: 200px;
  }
}

.time-container {
  label {
    color: $font-color-default;
    display: block;
    font-family: $font-family-default;
    font-size: 14px;
    margin: 14px 0;
  }

  input {
    @include inputTheme(96px);
    @include readOnlyInputTheme;
    padding: 0;
    text-align: center;
    background-color: $color-white;
  }
}

.picker-container label {
  color: $font-color-default;
  display: block;
  font-family: $font-family-default;
  font-size: 14px;
  margin: 14px 0;
}

::placeholder {
  color: $font-color-secondary;
  font-style: oblique;
}
