@import '../../assets/utilities/variables';

.login-page {
  align-items: center;

  .idp-login-container {
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    background-color: $color-white;
    color: $color-gray-1;
    height: 500px;
    width: 1024px;

    .image {
      object-fit: cover;
      object-position: 0;
      border-radius: 4px;
      max-width: 50%;
      flex: 1;
      overflow: hidden;
    }

    .content {
      button {
        cursor: pointer;

        &:hover {
          background: $secondary-light-yellow-color;
        }
      }
    }
  }
}
