@import '../variables.scss';
@import '../../ILQuestionItemMolecule/styles/CorrectIcon.scss';
@import '../../ILQuestionItemMolecule/styles/WrongIcon.scss';
@import '../../ILQuestionItemMolecule/styles/AnswerMarks.scss';

.topbar-container {
  display: flex;
  align-items: center;
  margin: 8px 0 24px 0;

  ul {
    display: flex;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background: $color-white;
    flex: 1;
    scroll-behavior: smooth;

    li {
      display: flex;
      align-items: center;
      list-style: none;
      border-right: 1px solid $menu-border-color;
      background: $color-white;

      .topbar-menu-item {
        width: 100%;
        height: 100%;
        margin: 0;
        height: 48px;
        width: 64px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        text-align: center;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: $menu-hover-color;
        }
      }

      .wrong:after,
      .correct:after {
        content: '';
        height: 16px;
        width: 16px;
        top: 28px;
        position: absolute;
        left: 36px;
      }

      .wrong:after {
        @include wrong-icon;
      }

      .correct:after {
        @include correct-icon;
      }

      .topbar-menu-item-selected {
        background-color: $secondary-blue-color;
        color: $secondary-blue-color;
        cursor: pointer;

        &:hover {
          background-color: $secondary-blue-color;
        }
      }
    }
  }

  .navigation-control {
    width: 64px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding-right: 5px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    background: $menu-primary-color;

    &:hover {
      background: $menu-hover-color;
    }

    &__disabled {
      cursor: initial;

      .icon {
        cursor: initial;
      }

      &:hover {
        background: $menu-primary-color;
      }
    }
  }

  .item-circle {
    display: inline-block;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
    color: $primary-blue-color;
    border: 1px solid $primary-blue-color;
    background-color: $color-white;
  }
}
