@import '../../assets/utilities/variables';
@import '../../assets/utilities/toetsplatformTheme';
@import '../../assets/utilities/containerVariables';

.update-counter {
  min-width: 144px;
  height: 32px;
  background: $primary-blue-color;
  color: $color-white;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
}

.news-container {
  width: 100%;
  box-sizing: border-box;
  margin: 8px 0 16px;

  .news-header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    @include icon($color-light-blue-1);
  }

  .news-content {
    margin: 0;
    font-size: 14px;
    background-color: transparent;
    color: $font-color-default;

    .news-footer {
      display: flex;

      .news-link {
        @include centered;
        width: 150px;
        margin-top: 24px;
        margin-right: 16px;
        font-size: 14px;
      }
    }

    h2 {
      margin: 0;
      font-size: 21px;
      color: $font-color-default;
      font-weight: normal;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .news-snippet {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .col-50 {
      div {
        margin-right: 56px;
      }
    }
  }
}
