@import '../../../assets/utilities/variables.scss';
@import './CheckMarkIcon';
@import './AnswerMarks.scss';
.choice-question,
.combined {
  .question-wrapper {
    padding: 20px;
    box-sizing: border-box;
    font-family: Helvetica, sans-serif;
    color: $font-color-alternative;
    font-size: 14px;
  }

  .qti-simpleChoice-list {
    list-style-type: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-top: 10px;
      width: 100%;
      padding: 10px 8px 10px 0;
      list-style-type: none;
      border: 1px solid $color-gray-9;
      border-radius: 4px;

      &::before {
        content: '';
        font-size: 23px;
        font-weight: 900;
        width: 24px;
        height: 24px;
        margin: 0 10px;
        border-radius: 3px;
        border: 2px solid $color-gray-9;
      }
    }

    .qti-simpleChoice-block.qti-candidateResponse {
      @include wrong-given-answer-styling;
      &::before {
        @include checkmark-icon;
      }
      &::after {
        @include wrong-answer-icon-only;
      }
    }

    .qti-simpleChoice-block.qti-candidateResponse.qti-simpleChoice-block.qti-correctResponse {
      @include correct-given-answer-styling;
    }

    .qti-simpleChoice-block.qti-correctResponse {
      &::after {
        @include right-answer-icon-only;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  // When maxchoices = 0, the simplechoice block should display a checkbox instead of radio button.
  [data-maxchoices='1'] ul li.qti-simpleChoice-block::before {
    color: $color-gray-9;
    font-weight: 100;
    padding: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  [data-maxchoices='1'] ul li.qti-simpleChoice-block.qti-candidateResponse::before {
    @include radio-selected;
    display: flex;
    border-color: transparent;
    justify-content: center;
    align-items: center;
    background: transparent;
  }

  .qti-choice-checkbox,
  .qti-identifier,
  .qti-points,
  .qti-choice-radio {
    display: none;
  }
}
