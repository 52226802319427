@import '../../assets/utilities/variables';

$planned-color: $color-blue-1;
$started-color: #ff8200;
$completed-color: $secondary-yellow-color;
$not-reviewed-color: #c9c9c9;
$not-released-color: $color-light-blue-1;
$released-color: $success-color;

@mixin status-indicator {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.planned-indicator::before {
  @include status-indicator;
  background-color: $planned-color;
}

.started-indicator::before {
  @include status-indicator;
  background-color: $started-color;
}

.completed-indicator::before {
  @include status-indicator;
  background-color: $completed-color;
}

.not-reviewed-indicator::before {
  @include status-indicator;
  background-color: $not-reviewed-color;
}

.not-released-indicator::before {
  @include status-indicator;
  background-color: $not-released-color;
}

.released-indicator::before {
  @include status-indicator;
  background-color: $released-color;
}

.completed-status {
  display: block;
  margin-left: 16px;
}
