@import '../../../assets/utilities/variables';

.tab-bar {
  height: 60px;
  padding: 0 20px;
  display: flex;
  box-sizing: border-box;
  background-color: $color-light-blue-5;
  border-bottom: 1px solid $color-gray-3;

  .tab {
    height: 100%;
    margin: 0 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    color: $font-color-blue;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $font-color-default;
    }
  }

  .tab-selected {
    color: $font-color-default;
    font-weight: bold;
    border-bottom: 3px solid $color-blue-1;
  }
}
