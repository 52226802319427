@import '../../assets/utilities/variables';

.progress-bar-wrapper {
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
  .progress-bar {
    height: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: $color-gray-2;

    .progress-slide {
      height: 100%;
      border-radius: 8px;
      background-color: $primary-blue-color;
      transition: all 0.5s ease;
    }
  }

  .progress-bar-label {
    color: $font-color-alternative;
    font-size: 16px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 20px;
  }
}
