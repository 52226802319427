@import './assets/utilities/variables.scss';
@import './assets/utilities/containerVariables';
html {
  height: 100%;
  overflow: auto;
}

body {
  background-color: $background-color;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: $font-family-default;
  height: 100%;
  position: absolute;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

//This is temporary, we will change the name to just main when all DNN is replaced
.main,
.main-dnn {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  h1 {
    height: 29px;
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $font-color-default;
    margin: 16px 0;
  }

  &__title {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include for-small-screens {
      width: 960px;
    }
    h1 {
      display: inline-block;
    }
    button {
      padding: 0 16px 0 8px;

      .icon {
        position: relative;
        top: 1px;
        margin-right: 8px;
      }
    }
  }
}

.main {
  width: 1280px;
  @include for-small-screens {
    width: 960px;
  }
}

.main-no-title {
  margin-top: 36px;
}

.main-dnn {
  width: 100%;
  .main-dnn-page-title {
    width: 1280px;
    margin: 16px auto;

    @include for-small-screens {
      width: 960px;
    }
  }
  &__title {
    width: 1280px;
    margin: 0 auto;
    @include for-small-screens {
      width: 960px;
    }
  }
}
