@import './variables';

@mixin inputTheme($width: 100%) {
  box-sizing: border-box;
  width: $width;
  height: 40px;
  padding-left: 5px;
  font-family: $font-family-default;
  font-size: 14px;
  color: $font-color-default;
  border: 1px solid $input-border;
  border-radius: 4px;
}

@mixin readOnlyInputTheme {
  &:read-only {
    background-color: $input-disabled-background;
    color: $font-color-secondary;
    pointer-events: none;
  }

  &:-moz-read-only {
    background-color: $input-disabled-background;
    color: $font-color-secondary;
    pointer-events: none;
  }
}

@keyframes loadingAnimation {
  0% {
    background-position: -380px 0;
  }
  100% {
    background-position: 380px 0;
  }
}

@mixin content-loader {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: loadingAnimation;
  background: $color-gray-7;
  background: linear-gradient(to right, $color-gray-7 8%, $color-gray-4 18%, $color-gray-7 33%);
  background-size: 750px 100px;
}

.white-message-container {
  background: $color-white;
  padding: 20px 16px;
  border-radius: 4px;
}

@mixin icon($color) {
  .icon {
    @content;

   .il-icon {
        fill: $color;
    }

    svg {
      .il-icon-stroke {
        stroke: $color;
      }
    }
  }
}
