@import '../../../../../assets/utilities/variables';
@import '../../../../../assets/utilities/toetsplatformTheme';

.result-panel {
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $color-white;

  &__header {
    width: 100%;
    padding: 24px 24px 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;

    .header-title {
      h2 {
        margin: 0 0 18px;
        font-size: 20px;
        color: $font-color-default;
      }

      .table-details {
        font-size: 14px;
        color: $font-color-default;
      }
    }

    .header-actions {
      display: flex;
      align-items: center;

      button,
      a {
        font-size: 14px;
        margin: 0;

        &:not(:last-child) {
          margin-right: 14px;
        }

        .icon {
          position: relative;
          top: 2px;
          margin-right: 4px;
        }
      }

      .download-button,
      .feedback-button {
        padding: 0 14px;

        .icon {
          padding-right: 4px;
        }
      }

      .close-button {
        @include icon($primary-blue-color);

        &:hover {
          @include icon($color-white);
        }
      }
    }
  }

  .dashboard-link-disabled {
    pointer-events: none;
    cursor: initial;
    color: $font-color-disabled;
    background: $color-gray-5;
    border-color: $color-gray-8;
    @include icon($font-color-disabled);
  }

  &__alert-container {
    padding: 0 24px 8px;
  }

  &__table-container {
    width: 100%;
    box-sizing: border-box;
  }

  .tab-table-container {
    position: relative;

    .table-action {
      position: absolute;
      display: flex;
      align-items: center;
      height: 60px;
      padding-right: 20px;
      right: 0;
      top: 0;
      font-size: 14px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        margin-left: 16px;

        .icon {
          margin-right: 4px;
        }
      }
    }
  }
}
