.result-domains-table {
  padding: 0 20px;

  .score-meter-column {
    width: 34px;
  }

  .domain-name-column {
    padding-left: 11px;
  }
}
