.saved-icon {
  @keyframes enlarge {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: enlarge 1s ease-in-out both;
}

.saving-status {
  margin-right: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 11px;
    margin: 4px;
  }

  &__saved {
    @keyframes hide {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      85% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    opacity: 0;
    animation-name: hide;
    animation-duration: 3000ms;
    animation-timing-function: linear;
  }
}
