$font-family-default: Helvetica, sans-serif;
$font-family-bold: 'HelveticaNeue Bold', 'HelveticaNeue-Bold', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;

$background-color: #f2f7fc;
$blue-header-color: #006a96;
$overlay-color: rgba(#70707a, 0.75);
$footer-color: #999;

$input-border: #cccccc;
$input-disabled-background: #f5f5f5;

$font-color-default: #333333;
$font-color-secondary: #cccccc;
$font-color-alternative: #171717;
$font-color-blue: #027dd2;
$font-color-faded: #9d9ca9;
$font-color-disabled: #70707a;

$shadow-color-default: rgba(169, 179, 189, 0.5);
$container-shadow-color: #e4eef8;

$primary-blue-color: #007dd2;
$primary-light-blue-color: #e5f2fa;
$primary-dark-blue-color: #006a96;
$secondary-yellow-color: #ffcc00;
$secondary-light-yellow-color: #f5d979;
$secondary-dark-yellow-color: #f5b400;
$error-color: #f52814;
$success-color: #38b54a;
$warning-color: #ffffbf;

$color-blue-1: #027dd2;

$color-light-blue-1: #7fbee8;
$color-light-blue-2: #acd5f0;
$color-light-blue-3: #bfdef4;
$color-light-blue-4: #f9fdff;
$color-light-blue-5: #f5fbff;

$color-gray-1: #70707a;
$color-gray-2: #e2e2e2;
$color-gray-3: #e6eff5;
$color-gray-4: #cccccc;
$color-gray-5: #f2f2f2;
$color-gray-6: #9d9c9c;
$color-gray-7: #e6e6e6;
$color-gray-8: #d9d9d9;
$color-gray-9: #d8d8d8;

$color-dark-gray-1: #222222;

$color-red-1: #e10031;
$color-red-2: #ffe5e5;
$color-red-3: #fee9e7;
$color-red-4: #fac4cb;

$color-green-1: #3cab48;
$color-green-2: #e2f9c8;
$color-green-3: #ebf6ec;

$color-white: #fff;
$color-black: #000;
