@import '../../../../assets/utilities/variables';
@import '../../review-variables';
@import '../../../../assets/utilities/containerVariables.scss';

.sidebar {
  width: $sidebar-width;
  min-width: $sidebar-width;
  height: calc(100% - #{$review-header-height});
  border-right: 1px solid $color-gray-7;
  overflow: auto;

  &__opened {
    display: block;
  }

  &__closed {
    display: none;
  }

  h2 {
    margin: 24px 0 0;
    text-align: center;
    font-size: 20px;
    color: $font-color-alternative;
  }

  .sidebar-progress-wrapper {
    padding: 28px 30px 0;

    .progress-bar-wrapper {
      margin-top: 0;
    }
  }

  .sidebar-sticky-wrapper {
    box-sizing: border-box;
    height: $sticky-container-height;
    position: sticky;
    @include centered;
    background-color: $background-color;
    z-index: 1;
    top: 0;
  }

  .finish-button-wrapper {
    margin: 10px 0 24px;
    display: flex;
    justify-content: center;

    button {
      padding: 0 10px;
    }
  }

  .sticky-divider {
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    background-color: $color-gray-7;
    position: sticky;
    top: calc(#{$sticky-container-height} - 1px);
    z-index: 1;
  }

  .sidebar-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 20px;
    border-bottom: 1px solid $color-gray-7;
    font-size: 16px;
    font-family: $font-family-default;
    color: $font-color-default;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 12px 0 $sidebar-shadow-color;
    }

    &__selected {
      background-color: $primary-light-blue-color;
      font-weight: bold;
    }

    &-status {
      width: 22px;
      height: 22px;
      margin-right: 16px;
      border-radius: 50%;
      transition: all 0.5s;
    }

    &-status__todo {
      background: $color-white;
      border: 2px solid $primary-blue-color;
    }

    &-status__done {
      background: $primary-blue-color;
      border: 2px solid $primary-blue-color;

      &::after {
        // TODO: change for proper svg check icon
        content: '\2713';
        color: $color-white;
        font-weight: bold;
        font-size: 20px;
        position: relative;
        display: block;
        top: -3px;
        left: 3px;
      }
    }

    &-status__active {
      background: linear-gradient(-90deg, $primary-blue-color 50%, $color-white 50%);
      border: 2px solid $primary-blue-color;
    }
  }
}
