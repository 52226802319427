.score-per-question {
  display: flex;
  justify-content: center;
  width: 100%;

  .view-answers-preserve {
    width: 0;
    flex: 1;
  }
}
