@import '../../../assets/utilities/variables.scss';
.planning-footer {
  font-family: $font-family-default;
  margin-bottom: 24px;

  .planning-button {
    padding: 0 24px;
    margin-left: 16px;

    &:last-child {
      margin-left: 0;
    }
  }
}
