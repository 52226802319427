@import '../../assets/utilities/toetsplatformTheme';
@import '../../assets/utilities/containerVariables';
@import '../../assets/utilities/variables';

.newsarchive-item {
  background: $color-white;
  display: flex;
  margin: 16px 0;
  border-radius: 4px;
  font-size: 14px;
  height: 250px;
  padding: 16px;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}

.newsarchive-content {
  flex: 1;
  padding: 16px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  .news-footer {
    display: flex;
    margin-top: auto;
    justify-content: flex-start;
    .news-link {
      @include centered;
      vertical-align: middle;
      width: 150px;
      margin-top: auto;
      margin-right: 16px;
      font-size: 14px;
    }
  }
}

.newsarchive-image {
  @include centered;
  flex: 1;
  max-width: 40%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
