@import '../../assets/utilities/variables.scss';

.detail-container {
  margin: 10px 0;

  .detail-panel {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    margin-bottom: 10px;
    background-color: $color-white;
    border-radius: 4px;

    .detail-panel-header {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      display: inline-block;
      margin: 0;
      padding: 8px 0;
      font-family: $font-family-bold;
      color: $font-color-default;
      font-weight: bold;
      font-size: 16px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 16px;

      tr {
        height: 60px;
        min-height: 60px;
        border-bottom: 1px solid $color-gray-7;
        border-top: 1px solid $color-gray-7;

        td {
          width: 50%;
          font-size: 14px;
          font-family: $font-family-default;
          color: $font-color-default;
          vertical-align: center;
        }
      }
    }
  }
}
