@import '../../assets/utilities/variables';
.alert-container {
  margin-bottom: 10px;

  .alert {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $font-color-default;
    font-family: $font-family-default;
    font-size: 14px;

    .alert-message {
      display: flex;
      align-items: center;

      .alert-title {
        font-weight: bold;
        margin-right: 5px;
      }

      .icon {
        margin-right: 12px;
        cursor: default;
      }
    }

    .alert-action {
      button {
        margin-left: 8px;
        padding: 0 16px;

      }

      .optional-button {
        background-color: $color-white;
        border: 1px solid $color-black;

        &:hover {
          background-color: $color-gray-5;
        }
      }
    }
  }

  .alert-error {
    background-color: $color-red-2;
  }

  .alert-attention {
    background-color: $warning-color;

    button {
      background-color: $secondary-dark-yellow-color;
      border: 1px solid $color-black;

      &:hover {
      background-color: $secondary-light-yellow-color;
      }
    }
  }

  .alert-warning {
    background-color: $warning-color;

    button {
      background-color: $secondary-dark-yellow-color;
      border: 1px solid $color-black;

      &:hover {
        background-color: $secondary-light-yellow-color;
      }
    }
  }

  .alert-success {
    background-color: $color-green-2;
  }
  .alert-note {
    background-color: $color-light-blue-1;
  }
}
