@import '../../assets/utilities/variables';
@import '../../assets/utilities/containerVariables';
@import '../../assets/utilities/toetsplatformTheme';
@import 'review-variables';

.review-page {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  z-index: 100;

  .review-container {
    flex: 1 0 auto;
    display: flex;
    height: 100%;
    overflow: hidden;

    &.sidebar-open .review-content .model-answer {
      width: calc(100% - #{$sidebar-width});
    }

    .review-content {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      overflow: auto;
      scroll-behavior: smooth;

      .model-answer {
        display: none;
        position: fixed;
        width: 100%;
        max-height: 60px;
        top: $review-header-height;
        z-index: 2;
        background-color: $color-white;

        &.model-answer-display {
          display: block;
        }
      }

      .review {
        position: absolute;
        width: 855px;
        margin: 0 auto;
        padding-top: 36px;
        left: 0;
        right: 0;

        &__full {
          width: 1280px;
        }

        @media screen and (max-width: 1350px) {
          width: calc(100% - 66px);

          &__full {
            width: calc(100% - 66px);
          }
        }

        .MuiCollapse-container {
          transition: height 1s linear;
        }

        .question-info {
          .max-score {
            margin: 20px 0 36px;
            color: $font-color-alternative;
            font-size: 16px;
            letter-spacing: 0;
          }
        }
      }

      .hidden-info {
        display: none;
      }
    }

    .scroll-up {
      @include centered;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      position: fixed;
      bottom: 40px;
      right: 35px;
      background-color: $scroll-icon-color;
      box-shadow: $scroll-icon-shadow;
      cursor: pointer;
      @include icon($color-white) {
        position: relative;
        right: 1px;
      }
    }
  }
}
