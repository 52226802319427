@import '../../../assets/utilities/variables.scss';
@import './AnswerMarks.scss';
.inline-choice-question,
.combined {
  .qti-inlineChoiceInteraction {
    display: inline-flex;
    align-items: center;
    select {
      position: relative;
      padding: 15px 10px;
      margin: 7px 10px 7px 5px;
      min-width: 200px;
      font-size: 14px;
      border: 1px solid $color-gray-9;
      border-radius: 3px;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .qti-correctResponse {
      text-align: left;
      display: inline;
      font-size: 11px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      position: relative;
      display: inline-block;
      white-space: nowrap;
      display: inline-flex;
    }
  }

  .qti-select-wrapper {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      top: 29px;
      right: 25px;
      border-radius: 3px;
      border-top: 6px solid $color-gray-9;
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
  .correct-answer,
  .incorrect-answer {
    .qti-correctResponse {
      @include answer-content;
    }
  }

  .correct-answer {
    select {
      @include correct-given-answer-styling;
    }
    .qti-correctResponse {
      background-color: $color-green-2;
      &:before {
        @include right-answer-icon-with-content;
      }
    }
  }
  .incorrect-answer {
    select {
      @include wrong-given-answer-styling;
    }
    .qti-correctResponse {
      background-color: $color-red-4;
      &:before {
        @include wrong-answer-icon-with-content;
      }
    }
  }

  .qti-points {
    margin-left: 4px;
  }
}

//Combined specific styling
.combined {
  .qti-inlineChoiceInteraction {
    .qti-correctResponse,
    .qti-points {
      display: inline-flex;
      font-size: 11px;
      font-weight: bold;
    }
  }
}
