@import '../../../assets/utilities/variables.scss';
@import 'localization.scss';
@import './CheckMarkIcon';
@import './AnswerMarks.scss';
.extended-text-question,
.combined {
  .extended-text-candidate-response,
  .qti-answer-model {
    position: relative;
    padding: 40px 10px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid $color-gray-9;
    border-radius: 3px;
  }

  .extended-text-candidate-response {
    display: flex;
    align-items: center;
  }

  .qti-answer-model,
  .extended-text-candidate-response {
    &:before {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 12px;
      color: $color-gray-6;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .incorrect-answer {
    @include wrong-given-answer-styling;
    &:after {
      @include wrong-answer-icon-only;
    }
  }

  .correct-answer {
    @include correct-given-answer-styling;
    &:after {
      @include right-answer-icon-only;
    }
  }

  .qtistyle-table {
    background: $color-white;
    border-spacing: 0px;
    font-size: 14px;
    td,
    th {
      padding: 10px;
      border: 1px solid $color-gray-9;
    }
  }

  .extended-text-feedback {
    width: 100%;
    padding: 10px;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    font-size: 16px;
    background: $color-gray-5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .qti-correctResponse {
    display: none;
  }

  .qti-correctResponse.extended-text-feedback {
    display: block;
  }

  .extended-text-candidate-response:before {
    content: $your-answer;
  }
  .qti-answer-model:before {
    content: $right-answer;
  }

  textarea {
    display: none;
  }
}
