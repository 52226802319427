@import '../../../assets/utilities/variables';
@import '../../../assets/utilities/toetsplatformTheme';

.filter-step {
  width: 100%;
  background-color: $color-white;
  padding: 40px 40px 8px;
  height: auto;
  box-sizing: border-box;

  h1,
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
