@import './CorrectIcon';
@import './WrongIcon';
@import '../../../assets/utilities/variables';

@mixin icon-styling {
  width: 25px;
  height: 25px;
  content: '';
  display: inline-flex;
  justify-content: center;
  margin-left: auto;
}

@mixin right-answer-icon-only {
  @include icon-styling;
  @include correct-icon;
}

@mixin wrong-answer-icon-only {
  @include icon-styling;
  @include wrong-icon;
}

@mixin right-answer-icon-with-content {
  @include icon-styling;
  @include correct-icon;
  margin-right: 6px;
  margin-left: -10px;
}

@mixin wrong-answer-icon-with-content {
  @include icon-styling;
  @include wrong-icon;
  margin-right: 6px;
  margin-left: -10px;
}

@mixin correct-given-answer-styling {
  background: $color-green-3;
  border: 1px solid $color-green-1;
}

@mixin wrong-given-answer-styling {
  background: $color-red-3;
  border: 1px solid $error-color;
}

@mixin radio-selected {
  content: '\0025C9';
  font-size: 35px;
}

@mixin answer-content {
  align-items: center;
  display: inline-flex;
  height: 17px;
  padding: 4px 12px 5px 0px;
  font-size: 11px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  margin-left: 8px;
}
