@import '../../assets/utilities/variables.scss';

.header {
  width: 100%;
  background-color: $color-white;
  box-shadow: 0 4px 8px 0 $container-shadow-color;

  &.header-idp {
    min-height: 80px;
    display: flex;
    align-items: center;

    .header-content {
      align-items: center;
      display: flex;
      padding: 0 32px;
      position: relative;
    }
  }

  .header-content {
    flex: none;
    margin: 0 auto;
    padding: 32px 32px 0;
    background-color: $color-white;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logoLink {
    display: inline-flex;
    align-items: center;
    color: $primary-blue-color;
    text-decoration: none;

    img {
      height: 55px;
      width: auto;
      @media screen and (max-width: 1280px) {
        height: 48px;
      }

      @media screen and (max-width: 1100px) {
        height: 36px;
      }
    }

    span {
      margin-left: 8px;
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

.header-hidden {
  display: none;
}
