@import '../../../assets/utilities/variables.scss';
@import '../../../assets/utilities/containerVariables.scss';

.questions-list {
  flex: 1;
  color: $font-color-default;

  h2 {
    font-weight: normal;
    font-size: 20px;
  }

  @include for-small-screens {
    max-width: 676px;
  }
}

.question-list-item {
  background: $color-white;
  margin: 16px 0 0 0;
  padding: 16px;
  border-radius: 4px;
  transition: all 0.2s linear;
  box-sizing: border-box;

  .score {
    margin-top: 0;
    font-size: 14px;
    font-weight: bold;

    span {
      font-weight: normal;
    }
  }
}

.selected {
  opacity: 0.5;
}

.question-list-item-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  border-radius: 4px;
  position: relative;

  &__meta {
    width: 100%;
    display: flex;
    background: $color-white;
    min-height: 56px;
    transition: all 0.2s linear;

    .selected {
      opacity: 0.5;
    }
  }

  &__actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
  }

  button {
    margin: 8px 16px;
    transition: background 0.2s linear;

    .icon {
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
  }

  .remove-button,
  .add-button {
    width: 100px;
  }

  .remove-button {
    font-size: 14px;
  }

  .order-button {
    margin: 8px 4px;
  }
}

.select-questions-wrapper {
  display: flex;
}

.sticky-sidebar {
  width: 268px;
  background: $color-white;
  color: $font-color-default;
  margin-left: 16px;
  margin-top: 16px;
  border-radius: 4px;
  position: sticky;
  padding: 16px;
  top: 16px;
  box-sizing: border-box;
  z-index: 0;
  @include for-ie-browser {
    position: fixed;
    top: 80px;
  }
}
