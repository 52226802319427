@import '../../../../assets/utilities/variables';
@import '../../../../assets/utilities/containerVariables';

.feedback-modal {
  margin-top: 20px;

  .feedback-container textarea {
    border: 1px solid $input-border;
  }

  .feedback-loading {
    height: 80px;
    @include centered;
  }
}
