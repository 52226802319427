@import '../../assets/utilities/variables.scss';
@mixin meterStyle($color) {
  border: 1px solid $color;
  color: $color;
}
.score-container {
  box-sizing: border-box;;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: $font-family-bold;
    font-size: 10px;
    font-weight: bold;
  }
}

.meter-none {
  @include meterStyle($color-gray-4);
}

.meter-default {
  @include meterStyle($primary-blue-color);
}

.meter-nok {
  @include meterStyle($error-color);
}

.meter-ok {
  @include meterStyle($success-color);
}
