@import '../../assets/utilities/containerVariables.scss';
.loading-indicator {
  @include centered;
}

.loading-container {
  height: 100%;
  widows: 100%;
  @include centered;
}
