.App {
  height: 100%;
  display: flex;
  flex-direction: column;

  .idp-avatar-wrapper {
    margin-left: auto;
    z-index: 2;

    .idp-button {
      margin: 22px 32px 0 0;
    }
  }
}
