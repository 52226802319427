.arranged-test-summary {
  padding: 16px 80px 20px 40px;
  background: #fff;

  .summary-navigation {
    display: flex;
    justify-content: flex-end;
  }
}

.question-list {
  max-width: 996px;
}
