.question-stats {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  .stat {
    margin: 0 32px;
    font-weight: bold;
    .stat-value {
      font-weight: normal;
      margin-left: 6px;
    }
    .score-container {
      display: inline-flex;
      margin-left: 6px;
    }
  }
}
