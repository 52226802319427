@import '../../assets/utilities/variables';

.filter-collapse {
  width: 33%;
  margin-right: 8px;
  transition: all 0.5s linear;

  &:last-child {
    margin-right: 0;
  }

  &__header {
    height: 48px;
    width: 100%;
    padding: 0 8px;
    box-shadow: 0 1px 0 0 $color-gray-4;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s linear;

    &__title {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__options {
    width: 100%;
    box-shadow: 0 1px 0 0 $color-gray-4;

    .option-item {
      width: 100%;
      height: 48px;
      padding: 11px 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}

.expanded-list {
  transition: all 0.5s linear;

  .filter-collapse__header {
    box-shadow: none;
  }

  .icon {
    svg {
      transform: rotate(180deg);
    }
  }
}
