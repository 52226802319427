@import '../../../assets/utilities/variables.scss';
.drop-down-menu {
  padding: 8px;
  margin-top: 8px;
  flex-direction: column;
  background-color: $color-white;
  display: none;
  border: 1px solid $color-gray-5;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba($color-black, 0.08);
  z-index: 2;
  box-sizing: border-box;
  position: absolute;

  &:hover {
    display: flex;
  }

  li {
    margin: 0;
    padding: 0;
    display: block;
    font-weight: normal;
  }
}

.drop-down-menu-item {
  text-align: left;
  padding: 10px 6px;
  display: block;
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 2;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-light-blue-color;
  }
}

.drop-down-menu-item-active {
  font-weight: bold;
}
