@import '../../assets/utilities/variables.scss';
.option-container {
  margin-top: 16px;
  cursor: default;
}

.option-disabled {
  opacity: 0.3;

  .select-input {
    cursor: default;
  }
}

.select-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 4px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .radio-checkmark {
      border: 2px solid $color-light-blue-1;
    }

    &:checked ~ .radio-checkmark:after {
      display: block;
      background-color: $color-light-blue-1;
    }

    &:checked ~ .checkbox-checkmark {
      background-color: $color-light-blue-1;
      border: 2px solid $color-light-blue-1;
    }

    &:checked ~ .checkbox-checkmark:after {
      display: block;
    }
  }
}

.select-label {
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 14px;
  padding-left: 26px;
}

.select-sub {
  color: $font-color-secondary;
  font-family: $font-family-default;
  font-size: 14px;
  margin: 8px 26px 0;
}

.select-input {
  min-width: 18px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 2px solid $input-border;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 2px solid $input-border;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 0;
    left: 4px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
