@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

.blue-header {
  height: 80px;
  min-height: 80px;
  width: 100%;
  background-color: $blue-header-color;
  z-index: 100;

  &-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    width: 1280px;
    margin: 0 auto;

    @media screen and (max-width: 1439px) {
      width: 960px;
    }
  }

  &__content {
    margin-right: 40px;

    a {
      font-family: $font-family-default;
      font-size: 16px;
      color: $color-white;
      text-decoration: none;
      @include icon($color-white);
    }
  }

  &__title {
    font-family: $font-family-default;
    font-size: 24px;
    color: $color-white;
    display: flex;
    justify-content: center;
  }
}
