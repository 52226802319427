@import '../variables.scss';
@import '../../../assets/utilities/toetsplatformTheme';

.question-selector {
  width: 130px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 10px;
  box-sizing: border-box;
  background: $color-white;
  border: 1px solid $selector-border-color;
  font-size: 14px;
  font-style: italic;
  color: $selector-font-color;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include icon($selector-font-color) {
    margin-left: 2px;
  }

  .question-overview {
    display: none;
    position: absolute;
    right: 0;
    background: $color-white;
    top: 55px;
    padding: 10px;
    width: 410px;
    box-shadow: 0 4px 8px 0 rgba($color-black, 0.08);
    border-radius: 5px;
    z-index: 1;
    flex-flow: wrap;

    .question-overview-item {
      font-style: normal;
      height: 40px;
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $primary-blue-color;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background: $correct-item-hover-color;
      }
    }

    .overview-item-selected.overview-item-incorrect {
      background: $incorrect-item-hover-color;
    }

    .overview-item-selected.overview-item-correct {
      background: $correct-item-hover-color;
    }

    .overview-item-incorrect {
      &:hover {
        background: $incorrect-item-hover-color;
      }
    }
  }

  .question-overview-collapsed {
    display: flex;
  }
}
