@import '../../assets/utilities/variables';
@import '../../assets/utilities/toetsplatformTheme';

.caesura-container {
  border-radius: 4px;
  background-color: $color-gray-5;
  margin-top: 20px;
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 14px;

  .caesura-table {
    width: 400px;
    text-align: center;
    padding: 5px 0;

    tr {
      margin-bottom: 10px;
    }

    th {
      box-sizing: border-box;
      height: 30px;
      font-weight: bold;

      &:not(:first-child) {
        width: 80px;
      }
    }

    td {
      box-sizing: border-box;
      height: 60px;

      &:not(:first-child) {
        width: 80px;
      }
    }

    input {
      @include inputTheme(80px);
      @include readOnlyInputTheme;
      padding: 0;
      text-align: center;
    }
  }
}
