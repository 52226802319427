@import '../../../assets/utilities/variables.scss';
@import './WrongIcon';
@import './CorrectIcon';
@import './AnswerMarks.scss';

.order-question,
.combined {
  .qti-simpleOrder-lists {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    h3 {
      font-size: 12px;
      color: $color-gray-6;
      margin: 0;
    }

    ul {
      padding: 0;
      li {
        width: auto;
        padding: 0px 24px;
        max-width: 390px;
        list-style-type: none;
        width: 100%;
        box-sizing: border-box;

        border-radius: 4px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-weight: bold;
        position: relative;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .qti-simpleOrder-block {
    border: 1px solid $color-gray-9;
  }

  .correct-answer:after,
  .incorrect-answer:after {
    position: absolute;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 23px;
    right: -35px;
    content: '';
  }

  .correct-answer {
    @include correct-given-answer-styling;
    &:after {
      @include correct-icon;
    }
  }
  .incorrect-answer {
    @include wrong-given-answer-styling;
    &:after {
      @include wrong-icon;
    }
  }

  .answer-content {
    flex: 1;
  }

  .given-answer-content {
    flex: 1;
    margin-right: 50px;
  }

  .qti-simpleOrder-value {
    display: none;
  }
}
