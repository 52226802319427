@import '../../../../../assets/utilities/containerVariables';
@import '../../../review-variables';

.sidebar-toggle {
  @include centered;
  box-sizing: border-box;
  position: fixed;
  top: 50vh;
  height: 60px;
  width: 25px;
  background-color: $primary-light-blue-color;
  border: 1px solid $primary-light-blue-color;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  z-index: 2;
  transition: all 0.5s;

  &:hover {
    background-color: $sidebar-toggle-hover-color;
    box-shadow: 2px 0 4px 0 $sidebar-shadow-color;
  }

  .icon {
    padding: 0;
  }
}
