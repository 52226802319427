@import '../../assets/utilities/variables.scss';
.viewer-wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $background-color;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
}

.loading-text {
  height: 35px;
  text-align: center;
  margin: -35px 0 0;
  box-sizing: border-box;
  padding: 0px;
}

.loading-hidden {
  display: none;
}
