@import '../../../../../assets/utilities/variables';
@import '../../../../../assets/utilities/containerVariables';
@import '../../../review-variables';

.scoring-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $font-color-alternative;
  font-family: $font-family-default;
  font-size: 16px;
  letter-spacing: 0;

  &:not(:first-of-type) {
    margin-top: 5px;
  }

  .scoring {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .max-score-label {
      width: 150px;
      text-align: left;
    }

    .point-selector {
      display: flex;
      box-sizing: border-box;
      height: 30px;
      width: 220px;
      border-radius: 4px;

      .point-button {
        @include centered;
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        border: $score-border;
        background-color: $color-gray-5;
        cursor: pointer;
      }

      .point-button-minus {
        border-radius: 4px 0 0 4px;
      }

      .point-button-plus {
        border-radius: 0 4px 4px 0;
      }

      .given-points {
        width: 160px;
        box-sizing: border-box;
        border-top: $score-border;
        border-bottom: $score-border;
        border-left: 0;
        border-right: 0;
        text-align: center;
        font-size: 16px;
        cursor: default;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-style: normal;
          color: $score-placeholder-color;
          font-size: 16px;
        }
      }
    }
  }
}
