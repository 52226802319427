@import '../../assets/utilities/variables';

$sidebar-width: 360px;
$sticky-container-height: 100px;
$review-header-height: 72px;

$scroll-icon-color: #007fe0;
$scroll-icon-shadow: 0 10px 23px 0 rgba(0, 127, 229, 0.48);
$modal-divider: #dce6ef;
$not-reviewed-color: #e02020;

$score-border: 1px solid $color-gray-2;
$score-placeholder-color: #babcbf;

$sidebar-shadow-color: #d3dee8;
$sidebar-toggle-hover-color: #ddecf5;
