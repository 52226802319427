@import '../../../assets/utilities/variables.scss';
@import './localization.scss';

.question-wrapper {
  font-family: $font-family-default;
  color: $font-color-alternative;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.2s linear;
  line-height: 24px;
}

.collapse-button {
  margin-left: auto;
  display: block;
  border: 0;
  background: inherit;
  outline: none;

  .icon {
    svg {
      transition: all 0.2s ease;
    }
  }
}

.qtistyle-border-thin {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    border: 1px solid $color-gray-9;
    padding: 4px;
  }
}

.qti-identifier {
  display: none;
}

.qti-mediaplayer {
  video {
    max-width: 100%;
  }
}

textarea {
  resize: none;
}

.qti-image,
textarea {
  max-width: 100%;
  box-sizing: border-box;
}

.feedback-item {
  position: relative;
  width: 100%;
  margin: 16px 0;
  padding: 32px 10px 10px 10px;
  box-sizing: border-box;
  color: $font-color-alternative;
  font-size: 14px;
  line-height: 25px;
  background: $color-gray-5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &::before {
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 12px;
    color: $color-gray-6;
    font-weight: bold;
    text-transform: uppercase;
    content: $feedback;
  }
}

.collapsed {
  .collapse-button {
    .icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.qtistyle-underline {
  text-decoration: underline;
}

.qti-bonus {
  display: none;
}
