@import '../../../../../assets/utilities/variables';

.close-info-modal {
  min-width: 520px;
  width: 520px;

  .close-test-description {
    margin: 24px 0;
    font-size: 14px;
    color: $font-color-default;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
