@import '../../assets/utilities/variables.scss';

.question-meta {
  flex: 1;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding-left: 16px;

  li {
    display: inline-block;
    margin-right: 16px;
    color: $font-color-default;

    &:before {
      content: "";
      height: 16px;
      width: 16px;
      margin-right: 4px;
      border-radius: 20px;
      background-color: $color-light-blue-3;
      display: block;
      float: left;
    }
  }
}
