.finish-review-modal.modal {
  width: 306px;
  max-width: 306px;

  .modal-top {
    .modal-title {
      margin: 0 auto;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-container {
      height: 50px;
    }

    .icon {
      cursor: default;
    }

    .modal-footer {
      display: flex;
      flex-direction: column;

      button {
        width: 230px;
        margin: 0;

        &.cancel-btn {
          margin-top: 4px;
        }
      }
    }
  }
}
