@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

.ReactTable {
  padding: 8px 0 24px 0;
  border: none;

  .rt-thead.-header {
    box-shadow: none;
  }

  .rt-thead {
    background-color: $primary-light-blue-color;
    .rt-tr {
      text-align: left;

      .rt-th {
        font-family: $font-family-bold;
        font-weight: bold;
        font-size: 14px;
        vertical-align: top;
        padding: 16px 16px 8px;
        border: none;

        &:focus {
          outline: none;
        }

        &.-sort-asc {
          box-shadow: none;

          .sort-header-container {
            .sort-icon-container {
              .icon {
                .collapse {
                  stroke: $primary-blue-color;
                }
              }
            }
          }
        }

        &.-sort-desc {
          box-shadow: none;

          .sort-header-container {
            .sort-icon-container {
              .icon {
                .expand {
                  stroke: $primary-blue-color;
                }
              }
            }
          }
        }

        .sort-header-container {
          display: flex;

          .sort-icon-container {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            position: relative;
            top: -5px;
            @include icon($color-black) {
              height: 7px;
              width: 7px;
            }
          }
        }
      }

      .rt-th.-sort-asc,
      .rt-th.-sort-desc {
        box-shadow: none;
      }
    }

    &.-filters {
      .rt-th {
        padding: 0 16px 16px;

        input {
          @include inputTheme;
          border-color: $color-gray-5;
        }

        select {
          @include inputTheme;
          margin: 0;
          border-color: $color-gray-5;

          option {
            color: $font-color-default;
            font-style: normal;
          }
        }

        .placeholder-option {
          color: $font-color-secondary;
          font-style: oblique;
        }
      }
    }
  }

  .rt-tbody {
    background-color: $color-white;
    border-bottom: 2px solid $color-gray-7;

    .rt-tr-group {
      border-bottom: 1px solid $color-gray-7;

      .rt-td {
        padding: 16px;
        font-size: 14px;
        line-height: 25px;
        vertical-align: top;
        border-right: none;
        white-space: normal;

        .icon-button {
          padding: 0;
          border-width: 0 1px 0 0;
          border-radius: 0;
          border-color: rgba($color-light-blue-1, 0.5);
          @include icon($font-color-blue) {
            padding: 0;
          }

          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
            border: none;
          }

          &:only-child {
            border-radius: 4px;
            border: none;
          }

          &:hover:enabled {
            @include icon($color-white);
          }

          &:disabled {
            @include icon($font-color-disabled);
          }
        }

        .text-button {
          margin-right: 16px;
          padding: 0 16px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .pagination-bottom {
    .pagination-menu {
      div:first-child {
        margin-left: auto;
      }

      ul:first-child {
        margin-left: auto;
      }
    }
  }

  .rt-noData {
    background: transparent;
    font-size: 16px;
    top: 55%;
  }
}
