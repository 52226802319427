@import '../../../../assets/utilities/variables';
@import '../../../../assets/utilities/toetsplatformTheme';
.step-section-disabled {
  opacity: 0.3;

  .select-input {
    cursor: default;
  }
}

.step-section-loading {
  .section-title {
    max-width: 350px;
    @include content-loader;

    h2 {
      opacity: 0;
    }
  }

  .select-option-container {
    width: fit-content;
    height: auto;
    @include content-loader;

    .option-container {
      opacity: 0;
    }
  }

  .select-input {
    cursor: default;
  }
}

.step-section {
  margin-bottom: 32px;

  .input-container {
    input {
      @include inputTheme(400px);
    }
  }

  .select-option-container {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;

    .option-container {
      margin-top: 0;
      min-width: 42px;
      padding-right: 32px;

      &:last-child {
        padding-right: 0;
      }

      .select-container {
        padding-top: 0;
      }
    }
  }
}
