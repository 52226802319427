@import '../utilities/variables.scss';
@import '../utilities/containerVariables.scss';
@import '../utilities/toetsplatformTheme.scss';

.tp-button {
  height: 40px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-family: $font-family-default;
  font-size: 14px;
  text-decoration: none;

  &:disabled {
    cursor: default;
  }

  &-primary {
    background-color: $primary-blue-color;
    color: $color-white;

    &:hover:not([disabled]) {
      opacity: 0.9;
    }

    &:disabled {
      color: $font-color-disabled;
      background-color: $color-gray-8;
    }
  }

  &-inverse {
    background-color: transparent;
    border: 1px solid $primary-blue-color;
    color: $primary-blue-color;
    @include icon($primary-blue-color);

    @include for-ie-browser {
      background-color: $color-white;
    }


    &:hover:not([disabled]) {
      background-color: $primary-blue-color;
      border: none;
      color: $color-white;
      @include icon($color-white);
    }

    &:disabled {
      color: $font-color-disabled;
      @include icon($font-color-disabled) {
        cursor: default;
      }
    }
  }

  &-secondary {
    background-color: $secondary-yellow-color;
    color: $color-dark-gray-1;
    @include icon($color-dark-gray-1);

    &:hover:not([disabled]) {
      background-color: $secondary-light-yellow-color;
    }

    &:disabled {
      color: $font-color-disabled;
      background-color: $color-gray-8;
    }
  }

  &-primary-light {
    background-color: $primary-light-blue-color;
    border: 1px solid #e1f3fc;
    color: $font-color-default;
    @include icon($primary-blue-color);

    &:disabled {
      color: $font-color-disabled;
      background-color: #f5f5f5;
      @include icon($font-color-disabled);
    }

    &:hover:not([disabled]) {
      background-color: $primary-blue-color;
      color: $color-white;
      @include icon($color-white);
    }
  }

  &-primary-dark {
    background-color: $primary-dark-blue-color;
    color: $color-white;
    @include icon($color-white);

    &:hover:not([disabled]) {
      background-color: #005f86;
    }

    &:disabled {
      background-color: #004f6f;
      color: #80a7b7;
      @include icon(#80a7b7);
    }
  }

  &-red {
    background-color: $color-red-2;
    color: $font-color-default;

    &:hover {
      background-color: #ffc8cc;
    }
  }

  &-success {
    background-color: rgba($success-color, 0.1);
    color: $color-white;
    @include icon($success-color);

    &:hover:not([disabled]) {
      background-color: $success-color;
    }
  }

  &-link {
    background-color: transparent;
    color: $font-color-blue;
    text-decoration: underline;
    @include for-ie-browser {
      background-color: $color-white;
    }

    &:hover:not([disabled]) {
      opacity: 0.9;
    }

    &:disabled {
      color: $font-color-disabled;
    }
  }

  &-icon {
    width: 32px;
    height: 32px;

    &:disabled {
      .icon {
        cursor: default;
      }
    }

    &-big {
      width: 40px;
      height: 40px;
    }
  }

  &-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-thin {
    height: 32px;
    font-size: 14px;
  }
}
