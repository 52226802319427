@import '../../../assets/utilities/variables';
@import '../../../assets/utilities/containerVariables';
@import '../review-variables';

.review-modal.modal {
  width: fit-content;

  .modal-top .modal-title {
    margin: 0 auto;
  }

  .review-modal-content {
    box-sizing: border-box;
    display: flex;
    margin-top: 26px;

    .review-option-container {
      box-sizing: border-box;
      width: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-of-type {
        border-right: 1px solid $modal-divider;
        padding-right: 24px;
      }

      &:last-of-type {
        padding-left: 24px;
      }

      img {
        width: 110px;
        height: 110px;
      }

      h3 {
        margin: 20px 0 30px;
        color: $font-color-default;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
      }

      a {
        width: 160px;
        @include centered;
      }
    }
  }
}
