@import '../../../../assets/utilities/variables';
@import '../../../../assets/utilities/toetsplatformTheme';
@import '../../review-variables';

.review-header {
  width: 100%;
  height: $review-header-height;
  min-height: $review-header-height;
  background-color: $blue-header-color;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 5px 0 $background-color;

  &__side {
    box-sizing: border-box;
    height: 100%;
    width: $sidebar-width;
    min-width: $sidebar-width;
    border-right: 1px solid $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;

    .nav-link {
      font-family: $font-family-default;
      font-size: 16px;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
      @include icon($color-white);
    }
  }

  &__navigation {
    width: 100%;
    height: 100%;
    display: flex;

    .review-button {
      width: 50%;
      height: 100%;
      border: none;
      border-radius: 0;
    }

    .review-back-button {
      border-right: 1px solid $color-white;

      .icon {
        transform: rotate(-90deg);
        margin-right: 4px;
      }
    }

    .review-next-button {
      .icon {
        transform: rotate(90deg);
        position: relative;
        top: -1px;
        margin-left: 4px;
      }
    }
  }
}
