@import '../../../assets/utilities/variables';

@mixin kti-bullet {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  margin-right: 8px;
  position: relative;
  top: -1px;
}
.chart-container {
  display: flex;
  margin-top: 16px;
  align-items: center;
  .pie-chart {
    width: 112px;
    height: 112px;
  }

  .kti-details {
    margin-left: 8px;
    padding-left: 0;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      color: $font-color-default;
      font-size: 14px;
      margin-bottom: 10px;

      span {
        margin-left: 4px;
        color: $font-color-secondary
      }
    }

    .knowledge::before {
      @include kti-bullet;
      background-color: $secondary-yellow-color;
    }

    .application::before {
      @include kti-bullet;
      background-color: $color-light-blue-1;
    }

    .insight::before {
      @include kti-bullet;
      background-color: $error-color;
    }
  }
}
