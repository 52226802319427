@import '../../assets/utilities/variables.scss';

.pagination-menu {
  display: flex;
  margin-top: 24px;
  align-items: center;
  font-size: 14px;

  .nav-back {
    margin-right: 24px;
  }

  .nav-next {
    margin-left: 24px;
  }
}

.pagination-navigation {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  li {
    padding: 4px 8px;
    margin: 0 4px;
    cursor: pointer;

    &:hover {
      background: $color-light-blue-2;
      font-weight: bold;
      border-radius: 4px;
    }
  }

  .spread-item {
    cursor: default;

    &:hover {
      background: none;
      font-weight: normal;
    }
  }

  .selected-page {
    background: $color-light-blue-2;
    font-weight: bold;
    border-radius: 4px;
  }
}

.icon {
  cursor: pointer;
}

.display-filter {
  margin-left: auto;
}

select {
  border: 1px solid $color-gray-5;
  min-height: 40px;
  min-width: 64px;
  border-radius: 2px;
  margin-left: 8px;
}
