@import '../../../assets/utilities/variables.scss';
@import './AnswerMarks.scss';

.text-entry-question,
.combined {
  .qti-textEntryInteraction {
    .qti-correctResponse {
      display: inline-flex;
      font-size: 11px;
      font-weight: bold;
      margin-left: 4px;
      ~ .qti-correctResponse {
        display: none;
      }
    }
  }

  .qti-itemBody {
    p {
      margin: 8px 0px;
      line-height: 19px;
      font-size: 14px;
    }
  }

  .qti-textEntryInteraction {
    display: inline-flex;
    align-items: center;
    margin: 4px 0;
  }

  .qti-candidateResponse {
    line-height: 16px;
    border: 1px solid $color-gray-9;
    border-radius: 3px;
    padding: 10px 16px;
    display: inline-block;
    margin-right: 8px;
  }

  .correct-answer {
    .qti-correctResponse {
      display: none;
    }
  }

  .correct-response {
    .qti-candidateResponse {
      @include correct-given-answer-styling;
    }
    .qti-correctResponse {
      display: none;
    }
  }

  .qti-points {
    margin-left: 6px;
  }

  .incorrect-answer {
    .qti-candidateResponse {
      @include wrong-given-answer-styling;
    }
    .qti-correctResponse {
      @include answer-content;
      background-color: $color-red-4;
      ~ .qti-correctResponse {
        display: none;
      }
      &:before {
        @include wrong-answer-icon-with-content;
      }
    }
  }

  .correct-answer {
    @include right-answer-icon-only;
  }
}

//Combined specific styling
.combined {
  .qti-textEntryInteraction {
    .qti-points {
      display: inline-flex;
      font-size: 11px;
      font-weight: bold;
    }
    .qti-points {
      margin-left: 4px;
    }
    .qti-candidateResponse {
      font-weight: bold;
    }
  }
}
