@import '../../assets/utilities/variables';
@import '../../assets/utilities/toetsplatformTheme';

.modal-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  z-index: 5;

  .modal {
    position: relative;
    box-sizing: border-box;
    width: 500px;
    height: auto;
    max-height: 95%;
    padding: 24px;
    overflow-y: auto;
    border-radius: 4px;
    background: $color-white;
    box-shadow: 0 0 16px 0 rgba($color-black, 0.5);

    .modal-top {
      display: flex;
      justify-content: space-between;

      .modal-title {
        margin: 0;
        font-size: 16px;
        color: $font-color-default;
      }

      .modal-close {
        @include icon( $font-color-default);
      }
    }

    .modal-sub {
      margin: 16px 0 24px 0;
      font-size: 14px;
      color: $font-color-default;
    }

    .modal-loader {
      margin: 70px 0;
    }

    .delete-btn,
    .ok-btn {
      width: 160px;
      margin-right: 24px;
    }

    .modal-footer {
      margin-top: 24px;

      button {
        margin-left: 24px;

        &:first-child {
          margin-left: 0;
        }
      }

      .ok-btn {
        padding: 0 24px;
      }
    }
  }

  .modal.error-modal {
    padding: 0;
    width: 480px;

    .modal-top {
      padding: 20px 24px;
      background-color: $color-red-3;
      align-items: center;

      .modal-title {
        display: flex;
        align-items: center;

        &-icon {
          margin-right: 14px;
        }
      }
    }

    .modal-body {
      margin: 24px;
      font-size: 14px;
      color: $font-color-default;

      button {
        margin-top: 32px;
      }
    }
  }

  .full-screen-modal {
    width: 100%;
    background-color: transparent;
    box-shadow: none;

    .modal-body {
      box-shadow: 0 0 16px 0 rgba($color-black, 0.5);
    }
  }
}
