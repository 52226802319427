@import '../../assets/utilities/variables.scss';

.planning-alert {
  .alert-container {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.planning-container {
  height: auto;
  width: 100%;
  background-color: $color-white;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  margin: 24px 0;
}

.planning-container.planning-disabled {
  .student-list-item, .select-input {
    cursor: default;
  }
}

.step-container {
  padding: 24px 0;

  .step-title {
    color: $font-color-default;
    font-family: $font-family-default;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  .step-subtitle {
    color: $font-color-default;
    font-family: $font-family-default;
    font-size: 16px;
    margin: 8px 0 16px;
  }
}

.with-divider {
  border-bottom: 2px solid $primary-light-blue-color;
}
