@import '../../assets/utilities/variables.scss';

.notifications {
  background-color: $color-white;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 40px;

  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0 16px;
  }

  h2 {
    display: inline;
    font-size: 16px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: $font-color-default;
    margin: 0;
  }

  .refresh-button {
    cursor: pointer;
    background-color: $color-light-blue-3;
    color: $primary-dark-blue-color;
    padding: 5px 10px;
    border: 0;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
      background-color: $primary-blue-color;
      color: $color-white;
    }
  }

  .loading-indicator {
    margin: 25px;
  }
}

.notifications-table {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0 20px 0 0;
    height: 50px;
  }

  .text-right {
    text-align: right;
    padding-right: 0;
  }

  tr {
    border-bottom: 1px solid $primary-light-blue-color;
    border-top: 1px solid $primary-light-blue-color;
  }

  hr {
    border-color: $primary-light-blue-color;
  }

  a, button {
    padding: 10px;
    color: $font-color-default;
    border: solid 1px $color-gray-4;
    font-weight: normal;
    background: $color-white;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    width: 150px;
    margin-left: auto;
    font-size: 14px;
    box-sizing: content-box;
    outline: none;

    &:hover {
      border: solid 1px $primary-blue-color;
      color: $primary-blue-color;
    }
  }
}

.error {
  color: $error-color;
}
