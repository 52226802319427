@import './variables.scss';

.question-result-wrapper {
  margin-top: 8px;
  background: $color-white;
  padding: 36px;

  h3 {
    margin: 0 0 12px 0;
    padding: 0;
    font-size: 20px;
  }

  .question-details {
    display: flex;
    p {
      margin-right: 48px;
      font-size: 14px;
    }
  }
}
