@import '../../assets/utilities/variables.scss';
.group {
  margin-top: 10px;
}

.group-header {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: $primary-light-blue-color;
  padding-right: 15px;
  height: 48px;
}

.group-check {
  display: flex;
  align-items: center;
  margin-left: 8px;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark,
    &:checked ~ .indeterminate {
      background-color: $color-light-blue-1;
      border: 2px solid $color-light-blue-1;
    }

    &:checked ~ .checkmark:after,
    &:checked ~ .indeterminate:after {
      display: block;
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 2px solid $input-border;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 0;
    left: 4px;
    width: 4px;
    height: 9px;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 2px solid $input-border;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 1px;
    left: 2px;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid $color-white;
  }
}

.group-name {
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-left: 8px;
}

.selected-item-counter {
  height: 17px;
  width: 138px;
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 14px;
  font-style: oblique;
  margin-right: 15px;
}

.empty-class {
  color: $font-color-default;
  font-family: $font-family-default;
  font-size: 14px;
  margin: 0 12px;
}

.student-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;

  .student-list-item,
  .student-list-item_checked {
    padding: 8px;
    width: 100%;
    height: 56px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid $input-border;

    &_checked {
      background-color: $primary-light-blue-color;
      border-bottom: 1px solid $primary-blue-color;
    }
  }

  .student {
    color: $font-color-default;
    font-family: $font-family-default;
    font-size: 14px;
  }
}

.MuiGrid-grid-xs-3 {
  padding: 0 12px;
}
