@import '../../../../assets/utilities/variables';
@import '../../../../assets/utilities/toetsplatformTheme';
@import '../../review-variables';

.review-block {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0 20px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $container-shadow-color;

  &:last-of-type {
    margin-bottom: 84px;
  }

  &-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    h3 {
      color: $font-color-default;
      font-family: $font-family-default;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
    }

    .header-info {
      display: flex;
      justify-content: space-between;
      width: 170px;
      @include icon($primary-blue-color);

      .review-status {
        color: $font-color-default;
        font-family: $font-family-default;
        font-size: 14px;
        letter-spacing: 0;

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          position: relative;
          top: -1px;
        }

        &.review-status-done::before {
          background-color: $success-color;
        }

        &.review-status-todo::before {
          background-color: $not-reviewed-color;
        }
      }
    }
  }

  &-content {
    .max-score {
      margin: 20px 0 36px;
      color: $font-color-alternative;
      font-size: 16px;
      letter-spacing: 0;
    }

    .review-answer {
      padding: 16px 16px 25px;
      border: 1px solid $color-gray-9;
      border-radius: 4px;

      &:not(:first-of-type) {
        margin-top: 16px;
      }

      h4 {
        text-transform: uppercase;
        margin: 0 0 10px 0;
        color: $font-color-faded;
        font-family: $font-family-default;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }

      .given-answer {
        font-family: $font-family-default;
        color: $font-color-alternative;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        white-space: pre-wrap;
      }

      .qti-answer-model {
        font-family: $font-family-default;
        color: $font-color-alternative;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    .review-scoring {
      margin: 24px 0;
      border-top: 2px solid $primary-light-blue-color;
      border-bottom: 2px solid $primary-light-blue-color;
      padding: 24px 0;
    }

    .review-feedback {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      button {
        padding: 0 12px;

        .icon {
          margin-right: 8px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
