@import '../../../../assets/utilities/variables.scss';

.question-filter {
  width: 100%;
  transition: all 0.5s linear;
  position: relative;
  z-index: 2;

  .icon {
    .expand-container {
      .expand {
        stroke: $font-color-default;
      }
    }
  }

  .filter-header {
    width: 100%;
    height: 56px;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: $primary-light-blue-color;
    cursor: pointer;

    &__title {
      font-size: 14px;
      padding: 0 8px;
    }
  }

  .filter-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    padding: 16px;
    border-radius: 5px;
    background-color: $color-white;
    transition: all 0.5s linear;

    &__main {
      display: flex;
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      button {
        width: 150px;
      }
    }
  }
}

.opened-filter {
  .filter-header {
    .icon {
      .expand-container {
        transform: rotate(180deg);
      }
    }
  }

  .filter-container {
    position: absolute;
    display: block;
  }
}

.filter-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  z-index: 1;
}
