@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

.admin-action-container {
  display: flex;
  justify-content: flex-end;

  .admin-action-section {
    margin-right: 8px;

    .tp-button-success {
      .il-icon {
        fill: $success-color !important;
      }

      &:hover {
        .il-icon {
          fill: $color-white !important;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
