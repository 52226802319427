@import '../../../assets/utilities/variables.scss';
@import './CheckMarkIcon';
@import './AnswerMarks.scss';

.match-question,
.combined {
  .qti-match-table {
    border: 1px solid $color-gray-5;
    border-spacing: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    td {
      padding: 3px 18px 0px;
      position: relative;
      border: 1px solid $color-gray-5;
      border-spacing: 0;
      .qti-points {
        @include answer-content;
        background-color: $color-green-2;
        margin-left: 14px;
        &:before {
          @include right-answer-icon-with-content;
        }
      }
    }

    .qti-simpleAssociableChoice-choice:before {
      border-radius: 100%;
      border: 2px solid $color-gray-9;
      align-items: center;
      content: '';
      margin: 0 10px;
      width: 20px;
      height: 20px;
      display: inline-flex;
    }

    .qti-candidateResponse:before {
      @include radio-selected;
      color: $color-gray-9;
      align-items: center;
      border-color: transparent;
      justify-content: center;
    }
  }

  .qti-simpleAssociableChoice-choice.qti-candidateResponse:not(.qti-correctResponse) {
    &:after {
      @include wrong-answer-icon-only;
    }

    .qti-points {
      display: none;
    }

    /*.qti-points {
      background-color: $color-red-4;
      &:before {
        @include wrong-answer-icon-with-content;
      }
    }*/
  }

  .qti-simpleAssociableChoice-choice.qti-correctResponse:not(.qti-candidateResponse) {
    .qti-points {
      display: none;
    }

    &:after {
      @include right-answer-icon-only;
      align-items: center;
    }
  }

  .qti-simpleAssociableChoice-choice:not(.qti-correctResponse):not(.qti-candidateResponse) {
    .qti-points {
      display: none;
    }
  }

  .qti-simpleAssociableChoice-cell {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid $color-gray-5;
    border-spacing: 0;
  }

  .qti-simpleAssociableChoice {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    font-size: 14px;
  }

  .qti-simpleAssociableChoice-source {
    padding: 10px;
    font-size: 14px;
  }

  .qti-choice-radio {
    display: none;
  }
}
