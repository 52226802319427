.close-test-modal {
  min-width: 680px;
  width: 680px;

  .close-test-modal-content {
    margin: 36px 0;

    .not-finished-list {
      display: grid;
      grid-template-columns: 33% 33% 33%;

      .option-container {
        padding-right: 16px;
      }
    }
  }
}
