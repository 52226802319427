@import '../../assets/utilities/variables';
@import '../../assets/utilities/toetsplatformTheme';

.pin-container {
  height: 40px;
  width: fit-content;
  display: flex;
  margin-left: 30px;
  border: 1px solid $input-border;
  border-radius: 4px;

  input {
    @include inputTheme(calc(100% - 40px));
    @include readOnlyInputTheme;
    border: none;
    border-radius: 4px 0 0 4px;
    padding: 0 0 0 5px;

    &:focus {
      border: none;
      outline: none;
    }
  }

  // hide built in eye icon from Edge
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .pin-toggle-button {
    height: 40px;
    width: 40px;
    background-color: $color-white;
  }
}

::placeholder {
  color: $font-color-secondary;
  font-style: oblique;
}
