.centered-column {
  width: 100%;
  text-align: center;
}

.right-aligned-column {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
