@import '../../assets/utilities/variables';
.result-summary-table {
  padding: 0 20px;

  table {
    tr {
      td {

        .list-button {
          padding: 1px 0;
        }

        .caesura-sum {
          padding-right: 14px;
          border-right: 1px solid $color-gray-7;

          &:last-child {
            padding-left: 14px;
            border-right: none;
          }
        }
      }
    }
  }
}
