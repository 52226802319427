@import '../../../assets/utilities/variables.scss';
.student-selector {
  background: $color-white;
  margin: 8px 28px 0 0;
  table {
    border-collapse: collapse;
    font-size: 12px;
    width: 220px;
    th {
      background: $primary-light-blue-color;
      text-align: left;
      font-size: 14px;
      padding: 16px;
    }

    tbody {
      tr {
        border-bottom: 1px solid $color-gray-4;
        td {
          padding: 8px 0 8px 16px;
        }
      }
    }
  }

  .score-cell {
    padding-right: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .student-name-wrapper {
    display: block;
    max-width: 135px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .student-name-tooltip {
    background: $color-white;
    box-shadow: 0 2px 11px $shadow-color-default;
    color: $font-color-default;
    padding: 8px;
    font-size: 12px;
  }

  .selector-item {
    cursor: pointer;
    &:hover {
      box-shadow: 0 2px 11px $shadow-color-default;
    }
  }

  .selector-item-disabled {
    opacity: 0.2;
    cursor: initial;
  }

  .selector-item-selected {
    background: $primary-light-blue-color;
    cursor: initial;
    &:hover {
      background: $primary-light-blue-color;
    }
  }
}
