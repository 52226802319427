@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme';

.breadcrumb {
  font-size: 14px;
  padding: 14px 0;
  display: inline-flex;
  align-items: center;
  margin: 8px 0;
  @include icon($primary-blue-color);

  span {
    display: inline-flex;
    align-items: center;
  }

  &__back {
    color: $primary-blue-color;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding-right: 0;
  }

  &__separator {
    color: $primary-blue-color;
    padding: 0 14px;
    line-height: 5px;
  }

  &__crumb {
    color: $primary-blue-color;
    font-size: 14px;

    a {
      color: inherit;
      text-decoration: none;
      padding: 0;
    }

    span {
      color: $font-color-default;
    }

    span.icon {
      padding: 0 14px;
    }
  }
}
