@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

$card-width: 250px !default;
$card-height: 140px !default;
$card-color-hover: $primary-blue-color;
$card-gutter: 20px;
$card-border-radius: 5px !default;
$card-use-border-radius: true;

@mixin card($card-width, $card-height, $bg-color: $color-white, $card-col: 4) {
  width: $card-width;
  background-color: $bg-color;
  min-height: $card-height;

  @if $card-use-border-radius {
    border-radius: $card-border-radius;
  }

  position: relative;
  border: 1px solid transparent;
  text-decoration: none;

  &:not(.no-hover):hover {
    border: 1px solid $card-color-hover;
    cursor: pointer;
  }

  &:nth-of-type(#{$card-col}n) {
    margin-right: 0;
  }
}

%card_button {
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: $color-black;
  border: 1px solid transparent;
  text-decoration: none;
  text-align: center;
  color: $color-white;

  &.-full {
    display: block;
    width: 100%;
  }
}

%card-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: $card-gutter;
  font-size: 32px;
  @include icon($color-gray-1)
}

.card {
  @include card(auto, $card-height);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
    margin-right: 8px;
    width: 250px;
  }

  @supports (not (display: grid)) or (-ms-ime-align: auto) {
    display: inline-block;
    margin-right: 8px;
    width: 250px;
  }

  .card__button {
    padding: 0 16px;
    background-color: transparent;
    border: 1px solid $color-gray-1;
    color: $font-color-default;
    cursor: pointer;

    &:hover {
      background: $card-color-hover;
      border: none;
      color: $color-white;
    }
  }

  .card--title {
    margin: 20px;
    width: 100%;
    font-size: 16px;
  }

  &--icon {
    @extend %card-icon;
  }

  &--title {
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 18px;
    font-size: 14px;
    color: $font-color-default;
    margin-bottom: 4px;
    max-width: $card-width - $card-gutter - 1;

    > strong {
      word-break: break-word;
    }
  }

  &--subtitle {
    height: 1.5em;
    padding-left: 20px;
  }

  &__info {
    flex: 1 0 100%;
  }

  &__button {
    @extend %card_button;
  }

  &-locked-icon {
    display: flex;
    position: absolute;
    top: 0;
    background-color: rgba($color-white, 0.5);
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: $color-gray-1;
    right: 0;
    bottom: 0;
    cursor: not-allowed;
    z-index: 100;
  }

  // Elements
  &__bottom-field {
    box-sizing: border-box;
    background-color: $color-white;
    padding: $card-gutter;
    font-size: 14px;
    min-height: 80px;
    width: 100%;
    vertical-align: bottom;
    border: solid 1px $color-white;
    display: flex;
    flex-wrap: wrap;
    @if $card-use-border-radius {
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
  }

  &__expand {
    width: 100%;
  }

  &:not(.no-hover):hover {
    border: 1px solid $card-color-hover;

    .card--title,
    .card--icon,
    .card--number {
      color: $card-color-hover;
    }

    .card--icon img {
      fill: $card-color-hover;
    }

    .card__button {
      background: $card-color-hover;
      border: none;
      color: $color-white;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
