@import '../../assets/utilities/variables.scss';
.title-container {
  display: flex;

  .title-box {
    width: 100%;
    height: 32px;
    min-height: 32px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 5px 0;
    box-sizing: border-box;
    font-family: $font-family-default;
    color: $font-color-default;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    overflow: auto;
    border: 1px solid $color-gray-9;
    border-radius: 4px;
  }
}

.panel-title {
  margin: 16px 0 8px 0;
  font-size: 14px;
  font-weight: bold;
  color: $font-color-default
}

.arranged-test-details {
  background: $color-white;
}

.table-test-details {
  font-size: 16px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  td {
    font-size: 14px;
  }

  .detail-title {
    width: 170px;
  }

  .detail-value {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
}

.details-table-container {
  min-width: 235px;
  max-width: 33%;
}
