@import '../../../assets/utilities/variables.scss';
@import '../../../assets/utilities/containerVariables.scss';

.navigation-button {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 10px 0 20px;
}

.top-section {
  display: flex;
  padding-bottom: 24px;

  .top-panel {
    height: fit-content;
    width: 50%;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 $container-shadow-color;

    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      margin-left: 16px;
    }

    &__header {
      padding: 16px 0 19px 22px;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid $color-gray-3;

      h2 {
        margin: 0;
        font-size: 20px;
        color: $font-color-default;
      }
    }
  }

  .result-details-table {
    padding: 0 20px;

    table {
      tr.feedback-row {
        vertical-align: top;

        td {
          padding: 22px 0;
        }

        .feedback-view {
          display: flex;
          justify-content: space-between;

          button {
            margin-left: 8px;
          }
        }
      }

      tr.feedback-button-row {
        td.feedback-cell {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          button {
            margin: 18px 0 20px;
            width: 170px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              margin-right: 8px;
            }
          }
        }
      }
    }

    table.info-table {
      tr {
        td {
          color: $color-black;

          &:first-child {
            width: 25%;
            font-weight: bold;
            color: $font-color-default;
          }
        }
      }
    }
  }
}

.bottom-section {
  width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 $container-shadow-color;

  .bottom-panel {
    background-color: $color-white;

    &__header {
      width: 100%;
      height: 100px;
      padding: 0 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      h2 {
        margin: 0;
        font-size: 20px;
        color: $font-color-default;
      }

      a {
        width: 190px;
        font-size: 14px;
        letter-spacing: 0;
        @include centered;

        .icon {
          margin-right: 8px;
          position: relative;
          top: 2px;
        }
      }
    }

    &__content {
      background-color: $color-white;
      min-height: 30px;
    }
  }
}
