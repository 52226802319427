@import '../../../assets/utilities/variables';

.student-list-modal {
  min-width: 693px;
  width: 693px;

  &__content {
    padding-top: 6px;

    &-section {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 10px;
      }

      h4 {
        margin: 0 0 12px;
        line-height: 26px;
        font-size: 14px;
        color: $font-color-default;
      }

      .students-list {
        width: calc(100% - 16px);
        margin-top: 12px;
        padding: 0;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 10px;
        list-style: none;

        .students-list-item {
          display: flex;
          align-items: center;
          height: 42px;
          padding: 0 10px;
          line-height: 1.2em;
          border-radius: 2px;
          font-size: 14px;
          color: $font-color-default;

          .icon {
            cursor: default;
            margin-right: 6px;
          }
        }
      }

      .students-list-ok {
        .students-list-item {
          background-color: rgba($color-green-1, 0.1);
        }
      }

      .students-list-nok {
        .students-list-item {
          background-color: rgba($color-red-1, 0.1);
        }
      }
    }
  }
}
