@import '../../assets/utilities/variables.scss';
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  z-index: 5;

  .modal-container {
    position: fixed;
    background: $color-white;
    width: 420px;
    height: auto;
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .delete-title {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    .delete-sub {
      font-size: 14px;
      margin: 16px 0 24px 0;
    }

    .delete-btn {
      width: 160px;
      margin-right: 24px;
    }

    .cancel-btn {
      padding: 0;
    }
  }
}
.loading-fullscreen {
  height: 100%;
  width: 100%;
  background: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  left: 0;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
