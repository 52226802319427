@import '../../assets/utilities/variables.scss';

.footer {
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 11px;
    color: $footer-color;
    margin-right: 18px;
    background-color: $color-white;
    padding: 4px 10px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
