@import '../../assets/utilities/variables.scss';

.feedback {
  padding: 0 24px 16px;

  &-intro {
    font-size: 14px;
    color: $font-color-default;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    textarea {
      width: 80%;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-family: $font-family-default;

      &::placeholder {
        font-size: 14px;
        font-style: oblique;
      }
    }

    button {
      font-size: 14px;
      padding: 0 14px;
    }

    .cancel-button {
      margin-right: 6px;
    }
  }
}

.feedback.feedback-vertical {
  padding: 0;

  .feedback-container {
    flex-direction: column;

    textarea {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
