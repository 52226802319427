@import '../../assets/utilities/variables';

.download-description {
  font-size: 14px;
  color: $font-color-default;

  p:last-child {
    margin-bottom: 0;
  }
}
