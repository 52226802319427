@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

.menu {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    font-family: $font-family-default;
    font-size: 12px;
  }
}

.menu-item {
  text-decoration: none;
  z-index: 2;
  cursor: pointer;
  margin-left: 32px;
  color: $font-color-default;
  padding: 8px 0;
  @media screen and (max-width: 1439px) {
    margin-left: 22px;
  }

  &:hover {
    color: $primary-blue-color;
    @include icon($primary-blue-color) {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover ul,
  &:active ul,
  &:focus ul {
    display: block;
  }
}

.menu-link {
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: 12px;
  font-family: $font-family-bold;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 8px;
  @include icon($font-color-default);
}

.menu-link-active {
  color: $primary-blue-color;
  border-bottom: 2px solid $primary-blue-color;
  padding-bottom: 0;
  @include icon($primary-blue-color);
}

.disabled-link {
  pointer-events: none;
}

.icon {
  padding-left: 2px;
}
