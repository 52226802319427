@import '../../../assets/utilities/variables.scss';
@import '../../../assets/utilities/containerVariables.scss';

.stepper {
  @include centered;
  width: 100%;
  margin: 24px 0;

  &__step {
    width: 42px;
    @include centered;
    flex-direction: column;
  }

  &__step-line {
    height: 2px;
    background: $color-gray-4;
    flex: 1;
    position: relative;
    top: -20%;
    transition: all 0.5s;

    &--finished {
      background: $primary-blue-color;
    }

    &--active {
      background: $primary-blue-color;
    }
  }

  &__step-bullet {
    width: 24px;
    height: 24px;
    background: $color-white;
    border: 2px solid $color-gray-4;
    border-radius: 50%;
    margin: 0 6px;
    transition: all 0.5s;

    &--finished {
      background: $primary-blue-color;
      border: 2px solid $primary-blue-color;
      cursor: pointer;

      &::after {
        content: "\2713";
        color: $color-white;
        font-weight: bold;
        font-size: 22px;
        position: relative;
        display: block;
        top: -3px;
        left: 3px;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    &--active {
      border: 2px solid $primary-blue-color;
    }
  }

  &__step-name {
    font-family: $font-family-default;
    color: $font-color-secondary;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-top: 2px;

    &--active {
      color: $font-color-default;
      font-weight: bold;
    }

    &--finished {
      color: $font-color-disabled;
      cursor: pointer;
    }
  }
}
