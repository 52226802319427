.inline-loader {
  display: flex;
  align-items: center;
  vertical-align: middle;

  .loader-dot {
    border-radius: 100%;
    background: #fff;
    margin: 2px;
    animation: dot-bounce 3s infinite ease-in-out both;

    &:nth-of-type(2) {
      animation-delay: 0.24s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.48s;
    }
  }
}

@keyframes dot-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
