@import '../utilities/variables';

$tp-table-border-color: $color-gray-7;
$tp-table-font-color: $font-color-default;

.tp-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $tp-table-border-color;

    &:last-child {
      border-bottom: none;
    }

    td {
      font-size: 14px;
      color: $tp-table-font-color;
    }
  }
}


.tp-table-info {
  tr {
    td {
      color: $color-black;

      &:first-child {
        width: 25%;
        font-weight: bold;
        color: $tp-table-font-color;
      }
    }
  }
}
