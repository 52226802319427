@import '../../../assets/utilities/variables';

.code-modal {
  width: 346px;
  max-width: 346px;

  &.code-modal-incorrect {
    .pin-container {
      border: 1px solid $error-color;
    }
  }

  .pin-container {
    width: 100%;
    margin-left: 0;
  }

  .modal-body {
    .modal-footer {
      margin-top: 48px;
    .ok-btn {
        margin-right: 0;
      }
    }
  }
}
