.col-layout {
  display: flex;
  width: 100%;

  .col-50 {
    width: 50%;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
