@import '../../assets/utilities/variables';
@import '../../assets/utilities/containerVariables';

.dashboard-score-container {
  @include centered;
  box-sizing: border-box;
  width: 150px;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    height: 32px;
    background: $color-gray-4;
    width: 1px;
    left: 100%;
    top: 25%;
  }

  .dashboard-score {
    box-sizing: border-box;
    padding: 20px 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-value {
      @include centered;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      font-size: 28px;

      .alphabetic {
        @include centered;
        flex-direction: column;

        .alphabetic-label {
          font-size: 10px;
          letter-spacing: 0;
          line-height: 12px;
        }
      }
    }

    .dash-score-default {
      background-color: $primary-blue-color;
      color: $color-white;
    }

    .dash-score-none {
      color: $font-color-default
    }

    .dash-score-ok {
      background-color: $success-color;
      color: $color-white;
    }

    .dash-score-nok {
      background-color: $error-color;
      color: $color-white;
    }

    &-label {
      margin-top: 30px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
