@import '../../assets/utilities/containerVariables.scss';
@import '../../assets/utilities/variables.scss';
@import '../../assets/utilities/toetsplatformTheme.scss';

.carousel-indicators {
  @include centered;
  width: 100%;
  margin: 16px 0;

  button {
    border-radius: 100%;
    background: $color-gray-9;
    border: 0;
    width: 8px;
    margin: 0 6px;
    height: 8px;
    cursor: pointer;
    padding: 0;

    &:disabled {
      background-color: $primary-blue-color;
    }
  }
}

.carousel__slider--horizontal {
  margin: 0 45px;
}

.single-slide {
  .carousel__slider--horizontal {
    margin: 0;
  }
}

.carousel-inner {
  position: relative;

  button {
    position: absolute;
    top: 172px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    background: initial;
    border: 0;
    outline: none;
    padding: 0;
    @include icon($primary-blue-color);
  }
}

.carousel__back-button {
  left: 0;
}

.carousel__next-button {
  right: 0;
}

.single-slide {
  margin-bottom: 8px;
}
