@import '../../assets/utilities/variables.scss';

.return-button {
  box-sizing: content-box;
  border: none;
  cursor: pointer;
  font-family: $font-family-default;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-light-blue-3;
  color: $primary-dark-blue-color;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;

  &:hover {
    background-color: $primary-blue-color;
    color: $color-white;
  }
}
