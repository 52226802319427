@import '../../assets/utilities/variables.scss';

.remindo-close-button {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-left: auto;

  &:hover {
    background: darken($primary-dark-blue-color, 5%);
  }

  svg {
    vertical-align: top;
  }
}

.remindo-header {
  height: 60px;
  z-index: 3;
  font-size: 17pt;
  font-weight: 600;
  background-color: $blue-header-color;
  color: $color-white;
  top: 0;
  width: 100%;
}

.remindo-header-content {
  width: 1000px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;

  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}

.remindo-player {
  height: 100%;
  background: $color-white;
  z-index: 102;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.remindo-player-visible {
  display: flex;
}

.viewer-wrapper {
  margin-bottom: 0 !important;
}
