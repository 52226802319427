@import '../../assets/utilities/variables';

.tooltip-student-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .tooltip-student-item {
    padding: 8px;
    font-family: $font-family-default;
    color: $font-color-default;
    font-size: 14px;
  }
}
