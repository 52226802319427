@mixin centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin for-small-screens {
  @media screen and (max-width: 1439px) {
    @content;
  }
}

@mixin for-ie-browser {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}
