@import '../../../../../../assets/utilities/variables';
@import '../../../../../../assets/utilities/containerVariables';
@import '../../../../../../assets/utilities/toetsplatformTheme';

.dashboard-student-table {
  .ReactTable.results-table {
    padding-top: 0;

    .rt-thead {
      padding: 0 20px 10px;
      background-color: $color-light-blue-4;
      height: 80px;

      .rt-tr {
        .rt-th {
          display: flex;
          align-items: flex-end;
          padding: 16px 0 8px 0;
          margin-left: 30px;

          .sort-header-container {
            align-items: flex-end;

            .sort-header-title {
              max-width: 85px;
              line-height: 1.2em;
              max-height: 3.6em;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              white-space: normal;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              position: relative;
              top: 2px;
            }

            .sort-icon-container {
              margin-left: 2px;
            }
          }
        }

        .rt-th.result-feedback-column {
          padding: 0;
        }

        .rt-th:first-child,
        .rt-th.result-score-column,
        .rt-th.result-feedback-column {
          margin-left: 0;
        }
      }
    }

    .rt-tbody {
      padding: 0 20px;
      overflow: hidden;

      .rt-tr-group {
        .rt-tr {
          display: flex;
          align-items: center;

          .rt-td {
            padding: 16px 0;
            margin-left: 30px;
          }

          .rt-td:first-child,
          .rt-td.result-score-column,
          .rt-td.result-feedback-column {
            margin-left: 0;
          }
        }

        .rt-expandable {
          padding: 0;
          display: block;
          position: relative;
          right: 10px;
        }
      }
    }

    .pagination-menu {
      padding: 0 8px;
    }
  }

  .student-link {
    text-decoration: none;
    color: $color-black;

    &:hover {
      text-decoration: underline;
    }
  }

  .filled-grade {
    width: 40px;
    height: 40px;
    border: none;

    span {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .filled-grade.meter-nok {
    background-color: rgba($error-color, 0.1);
    color: $error-color;
  }

  .filled-grade.meter-ok {
    background-color: rgba($success-color, 0.2);
    color: $success-color;
  }

  .score-container.score-container-big {
    width: 40px;
    height: 40px;

    span {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .results-score-column {
    width: 100%;
    text-align: left;

    .score-ok {
      color: $success-color;
    }

    .score-nok {
      color: $error-color;
    }

    .score-none {
      color: $color-gray-4;
    }
  }

  .alpha-grade-ok {
    color: $success-color;
  }

  .alpha-grade-nok {
    color: $error-color;
  }

  .alpha-grade-none {
    color: $font-color-default;
  }

  .expander-cell {
    display: flex;
    height: 45px;
    cursor: pointer;
    @include icon($primary-blue-color) {
      padding: 0;
      position: relative;
      top: 2px;
      left: 4px;
    }

    .given-feedback {
      .icon {
        left: 3px;
      }
    }

    .opened-feedback {
      width: 23px;
      height: 23px;
      box-sizing: border-box;
      background-color: $primary-blue-color;
      border-radius: 4px;
      @include icon($color-white);
    }
  }
}
